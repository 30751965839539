* {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  -webkit-tap-highlight-color: #002f55;
  /* make transparent link selection, adjust opacity 0 to 1.0 */
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-overflow-scrolling: touch;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  /* -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease; */
}

html, body {
  background-color: #002f55;
  width: 100%;
  height: 100%;
}

.App {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-image: url("./Assets/Images/spheres-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
}

p {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  /* color: #002f55; */
  margin-bottom: 10px;
}

h1 {
  font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
  font-size: 48px;
  line-height: 1;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

h2 {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 22px;
  line-height: 1.2;
  font-weight: normal;
  color: #ffffff;
  /* margin-bottom: 10px; */
}
h3 {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 1.2;
  font-weight: normal;
  color: #f7c921;
  /* margin-bottom: 10px; */
}

/*UNORDERED LIST*/
ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}
li {
  font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #ffffff;
  /* margin-bottom: 10px; */
  /* margin: 0px 0px 20px 0px; */
  cursor: pointer;
}
ul li {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23002F55;%7D%0A%3C/style%3E%3Ccircle class='st0' cx='3' cy='5' r='3'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 0px 4px; 
  padding-left: 20px;
  /* margin-bottom: 5px; */
}

.txt-center {
  text-align: center !important;
}

.mb10 {
  margin-bottom: 10px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}

sub, sup {
	vertical-align: baseline;
	position: relative;
	font-size: 60%;
}
sub {
	bottom: -0.6em;
}
sup {
	top: -0.6em
}

.btn {
  display: inline-block;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  padding: 15px 80px;
  background-color: #943AF4;
  margin-top: 10px;
  cursor: pointer;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}
.btn:hover {
  background-color: #CA9DFA;
}

.link-out {
  text-decoration: underline;
  cursor: pointer;
}

@media only screen and (min-width: 1920px) {

  /*   */

}

@media only screen and (max-width: 1366px) {

/*   */

}

@media only screen and (max-width: 1220px) {

  /*  */

}

@media only screen and (max-width: 1024px) {

  .adaptive-break {
    display: none;
  }

}

@media only screen and (max-width: 768px) {

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 24px;
  }

}

@media only screen and (max-width: 560px) {

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 20px;
  }

}
