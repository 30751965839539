

#modal-content h1 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 36px;
    line-height: 1.4;
    color: #002F55;
    /* text-transform: uppercase; */
    text-align: left;
    margin: 0;
    padding: 0;
}
#modal-content h2 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 18px;
    line-height: 1.4;
    color: #002F55;
    text-align: left;
    margin: 0;
    padding: 0;
}
#modal-content h3 {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 16px;
    line-height: 1.4;
    color: #002F55;
    text-align: left;
    margin: 0;
    padding: 0;
}
.modal-head {
    position: absolute;
    top: 20px;
}



.mb10 {
    margin: 0px 0px 10px 0px !important;
}
.last {
    margin-bottom: 10px !important;
}
.isi-footnote {
    margin: 20px 0px 0px 0px;
    padding: 20px 0px 20px 0px;
}
.isi-footnote p {
    text-align: center;

}


/* calculation */

.calculation-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.calculation-wrap .calc-item p {
    font-family: 'DIN2014Regular', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1.2;
    color: #002F55;
    text-align: center;
    margin: 0;
    padding: 0;
}
.calc-item {
    padding: 10px 10px;
}
.calc-item .line {
    width: 100%;
    height: 2px;
    margin: 10px 0px;
    background-color: #002F55;
}
.calculation-wrap .calc-item p.big-p {
    font-family: 'DIN2014Bold', Arial, Helvetica, sans-serif;
    font-size: 46px;
    line-height: 1.2;
    color: #002F55;
    text-align: center;
    margin: 0;
    padding: 0;
}
.calculation-wrap .calc-item p.big-p.result {
    color: #943af4;
}


@media only screen and (max-width: 1200px) {
    .calculation-wrap .calc-item p, .calculation-wrap .calc-item p.big-p {
        font-size: 3vw;
    }
}

@media only screen and (max-width: 960px) {

    /* .modal-content-phase {
        max-height: unset;
    }

    #phaseiii .phaseiii-content {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    #phaseiii .phaseiii-column {
        width: 100%;
        margin-bottom: 20px;
    }

    #phaseiii .phaseiii-content :nth-child(1) { 
        order: 0; 
    }
    #phaseiii .phaseiii-content :nth-child(0) { 
        order: 2; 
    }
    #phaseiii .phaseiii-content :nth-child(2) { 
        order: 1;
        display: block;
    }

    #phaseiii .col1 { 
       margin-bottom: 40px;;
    }


    #phaseiii .phaseiii-column .flex-column {
        display: flex;
    }
    

    #phaseiii .phaseiii-arrow {
        margin: 20px 0px;
    }

    #phaseiii .phaseiii-textbox {
        width: 100%;
    }

    #phaseiii .top-line {
        bottom: 0;
        top: unset;
    }

    #phaseiii .phaseiii-textbox-line {
        width: 4px;
        height: 100px;
        position: absolute;
        right: 50%;
        top: -44px;
        margin-left: auto;
    } */


}